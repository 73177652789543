import * as Dialog from "@radix-ui/react-dialog";
import { DotLottiePlayer } from "@dotlottie/react-player";
import "@dotlottie/react-player/dist/index.css";
import Image from "next/legacy/image";
import { Button } from "./ui/button";
import { Check, X } from "@phosphor-icons/react";
import LucidMascot from "@/assets/images/lucid-mascot.png";
import { useRouter } from "next/router";
import startupBenefits from "@/assets/json/startup-benefits.json";
import investorBenefits from "@/assets/json/investor-benefits.json";
import angelBenefits from "@/assets/json/angel-benefits.json";
import contentBenefits from "@/assets/json/content-benefits.json";

const PaymentSuccess = ({ type, open, setOpen }) => {
  const router = useRouter();
  function toPlan() {
    router.push("/account/plan");
    // setOpen(false);
  }
  function toMyStartup() {
    router.push("/account/startup");
    // setOpen(false);
  }
  function toMyVC() {
    router.push("/account/vc");
    // setOpen(false);
  }
  function toMyAngel() {
    router.push("/account/angel");
    // setOpen(false);
  }
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <>
            <div className='fixed inset-0 z-[89] w-screen h-screen bg-background/80 backdrop-blur-sm'></div>
            <div className='fixed top-0 z-[89] left-0 w-full h-full lg:flex'>
              <div className='hidden w-full lg:inline'>
                <DotLottiePlayer
                  autoplay
                  loop
                  mode='normal'
                  src='https://lottie.host/d32eae98-9229-4dd2-8832-55c0eb02a2ea/30DNE5oGCm.lottie'
                />
              </div>
              <div className='w-full'>
                <DotLottiePlayer
                  autoplay
                  loop
                  mode='normal'
                  src='https://lottie.host/d32eae98-9229-4dd2-8832-55c0eb02a2ea/30DNE5oGCm.lottie'
                />
              </div>
            </div>
          </>
        </Dialog.Overlay>
        <Dialog.Content className='fixed bg-white overflow-hidden p-0 pt-8 lg:pt-0 text-background left-[50%] top-[50%] z-[99] grid w-full max-w-4xl translate-x-[-50%] translate-y-[-50%] gap-4 border shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full'>
          <div className='relative items-center h-full px-6 overflow-hidden lg:px-0 lg:flex lg:text-left'>
            <button
              onClick={() => setOpen(false)}
              type='button'
              className='absolute z-10 top-4 right-4'
            >
              <X weight='bold' className='text-2xl text-background' />
            </button>
            <div className='relative flex items-center bg-transparent lg:justify-center lg:h-full lg:w-40 lg:bg-gradient-to-tr from-yellow-1 to-red-1'>
              <div className='inline-block flex-shrink-0 relative h-20 lg:h-72 lg:translate-x-14 aspect-[199/320]'>
                <Image
                  src={LucidMascot.src}
                  alt=''
                  layout={"fill"}
                  objectFit={"cover"}
                />
              </div>
            </div>
            <div className='relative flex-1 pt-4 pb-8 text-sm lg:pt-12 lg:pr-8 lg:pl-32'>
              <p className='text-4xl font-bold tracking-tight lg:text-5xl font-display'>
                Welcome to the <br /> Lucidity Insights Family
              </p>
              {type === "trial" && (
                <p className='mt-5 leading-relaxed opacity-80'>
                  Enjoy your 7 day free trial and explore our comprehensive
                  collection of special reports, spotlights, videos, articles,
                  infobytes, database of investor and startup profiles, and much
                  more.
                </p>
              )}
              {type !== "trial" && (
                <>
                  <p className='mt-5 leading-relaxed opacity-80'>
                    Thank you for your purchase!
                  </p>
                  <p className='mt-3 leading-relaxed opacity-80'>
                    As of now you can enjoy unlimited content from Lucidity
                    Insights. Here is a reminder of the benefits of your current
                    plan.
                  </p>
                </>
              )}
              {type === "content" && (
                <>
                  <p className='mt-3 font-medium'>
                    Researcher All-Access Pass :
                  </p>
                  <div className='flex flex-col mt-4 space-y-2 text-xs text-background'>
                    {contentBenefits.benefits.map((item, index) => (
                      <div
                        className='inline-flex items-start space-x-2.5'
                        key={index}
                      >
                        <div className='inline-flex items-center justify-center flex-shrink-0 w-[18px] h-[18px] text-white rounded-full bg-gradient-to-tr from-green-300 to-green-500'>
                          <Check weight='bold' className='text-xs' />
                        </div>
                        <span>{item}</span>
                      </div>
                    ))}
                    {contentBenefits.exclude.map((item, index) => (
                      <div
                        className='inline-flex items-start space-x-2.5'
                        key={index}
                      >
                        <div className='inline-flex items-center justify-center flex-shrink-0 w-[18px] h-[18px] text-white rounded-full bg-gradient-to-tr from-red-300 to-red-500'>
                          <X weight='bold' className='text-xs' />
                        </div>
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {type === "startup" && (
                <>
                  <p className='mt-3 font-medium'>Startup Registration :</p>
                  <div className='flex flex-col mt-4 space-y-2 text-xs text-background'>
                    {startupBenefits.map((item, index) => (
                      <div
                        className='inline-flex items-start space-x-2.5'
                        key={index}
                      >
                        <div className='inline-flex items-center justify-center flex-shrink-0 w-4 h-4 text-white rounded-full bg-gradient-to-tr from-green-300 to-green-500'>
                          <Check weight='bold' className='text-xs' />
                        </div>
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {type === "vc" && (
                <>
                  <p className='mt-3 font-medium'>VC Registration :</p>
                  <div className='flex flex-col mt-4 space-y-2 text-xs text-background'>
                    {investorBenefits.map((item, index) => (
                      <div
                        className='inline-flex items-start space-x-2.5'
                        key={index}
                      >
                        <div className='inline-flex items-center justify-center flex-shrink-0 w-4 h-4 text-white rounded-full bg-gradient-to-tr from-green-300 to-green-500'>
                          <Check weight='bold' className='text-xs' />
                        </div>
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {type === "angel" && (
                <>
                  <p className='mt-3 font-medium'>Angel Investor Registration :</p>
                  <div className='flex flex-col mt-4 space-y-2 text-xs text-background'>
                    {angelBenefits.map((item, index) => (
                      <div
                        className='inline-flex items-start space-x-2.5'
                        key={index}
                      >
                        <div className='inline-flex items-center justify-center flex-shrink-0 w-4 h-4 text-white rounded-full bg-gradient-to-tr from-green-300 to-green-500'>
                          <Check weight='bold' className='text-xs' />
                        </div>
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {type === "content" ? (
                <Button
                  onClick={toPlan}
                  className='w-full h-auto py-3 lg:py-3.5 mt-5 lg:mt-8 bg-gradient-to-b from-background/80 border-y hover:bg-background/80 border-white/20 text-white to-background'
                >
                  Go to My Plan
                </Button>
              ) : (
                <div className='flex gap-x-3'>
                  <Button
                    onClick={toPlan}
                    className='w-full h-auto py-3 lg:py-3.5 mt-5 lg:mt-8 bg-gradient-to-b from-background/80 border-y hover:bg-background/80 border-white/20 text-white to-background'
                  >
                    Go to My Plan
                  </Button>
                  <Button
                    onClick={
                      type === "startup"
                        ? () => toMyStartup()
                        : type === "angel"
                          ? () => toMyAngel()
                          : () => toMyVC()
                    }
                    className='w-full h-auto py-3 lg:py-3.5 mt-5 lg:mt-8 bg-gradient-to-b from-background/80 border-y hover:bg-background/80 border-white/20 text-white to-background'
                  >
                    Go to My {type === "startup" ? "Startup" : type === "angel" ? "Angel Investor" : "VC"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default PaymentSuccess;
